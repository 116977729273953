@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
.lottie-icon svg {
  transform: unset !important;
}

@font-face {
  font-family: "Mabry";
  src: local("Mabry"), url("./fonts/mabry-regular-pro.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mabry";
  src: local("Mabry"), url("./fonts/mabry-bold-pro.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mabry Mono";
  src: local("Mabry Mono"), url("./fonts/mabry-mono-regular-pro.otf");
  font-weight: normal;
  font-style: normal;
}

@layer base {
  h1 {
    @apply text-6xl font-bold gradient-text py-2 mb-3;
  }
  h2 {
    @apply text-4xl font-bold mb-3;
  }
  h3 {
    @apply text-3xl font-bold mb-3;
  }
  h4 {
    @apply text-2xl font-bold mb-3;
  }
  ::-webkit-scrollbar {
    @apply bg-gray-light w-3;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary border-black;
  }
  ::selection {
    @apply text-black bg-primary;
  }
}

@layer utilities {
  .aspect-ratio-1 {
    padding-bottom: 100%;
  }
  .scroll-gradient {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
  .is-mac .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .is-mac .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .alt-highlight::selection {
    @apply text-primary bg-black;
  }
}

@layer components {
  .slider {
    @apply h-3 bg-white bg-opacity-5;
    width: calc(100% + 8px);
    margin-left: -4px;
  }
  .slider-track {
    @apply bg-white bg-opacity-5 h-3 relative cursor-pointer;
  }
  .slider-track.slider-track-0 {
    @apply bg-white bg-opacity-100;
  }
  .slider-mark {
    @apply bg-white opacity-80 h-2 w-0.5 cursor-pointer top-0.5 mx-1;
    z-index: 10 !important;
  }
  .slider-mark.slider-mark-dark {
    @apply bg-black opacity-100;
  }
  .slider-thumb {
    @apply bg-white border-2 border-gray-light w-3 h-6 -mt-2.5 top-1 cursor-pointer;
    z-index: 20 !important;
  }
  .slider-thumb.active {
    @apply outline-black;
    box-shadow: 0px 0px black;
  }
  .gradient-text {
    @apply text-transparent bg-clip-text bg-gradient-to-b from-secondary to-primary;
  }
  #walletconnect-wrapper {
    @apply text-black;
  }
  .walletconnect-search__input {
    @apply placeholder:text-black placeholder:opacity-60;
  }
}
